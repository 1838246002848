import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import FannedCards from './FannedCards';

const HandViewer = ({
  onCardClick,
  selectedHandCardIndex = -1,
  minimized = false,
  onToggleMinimize,
  onUseHandCard,
  game
}) => {
  const selectedPlayer = localStorage.getItem(`currentPlayer_${game.id}`);
  const playerLabel = game?.attrs?.[`player${selectedPlayer}Label`] || `Player ${selectedPlayer}`;  
  
  // If no player is selected, don't render the component
  if (!selectedPlayer) {
    return null;
  }

  // Get the correct hand cards for the selected player
  const getHandCards = () => {
    const cardFlipState = game?.attrs?.cardFlipState;
    if (!cardFlipState) return [];

    return selectedPlayer == "1"
      ? (cardFlipState.player1?.handCards || [])
      : (cardFlipState.player2?.handCards || []);
  };

  const handCards = getHandCards();  
  
  // Get the current state from game attributes
  const getStateFromGame = () => {
    const cardFlipState = game?.attrs?.cardFlipState;
    if (!cardFlipState) return {};

    return {
      player1Deck: cardFlipState.player1?.deck || [],
      player1DiscardPile: cardFlipState.player1?.discardPile || [],
      player1CurrentCards: cardFlipState.player1?.currentCards || [],
      player1HandCards: cardFlipState.player1?.handCards || [],
      player1ExilePile: cardFlipState.player1?.exilePile || [],
      
      player2Deck: cardFlipState.player2?.deck || [],
      player2DiscardPile: cardFlipState.player2?.discardPile || [],
      player2CurrentCards: cardFlipState.player2?.currentCards || [],
      player2HandCards: cardFlipState.player2?.handCards || [],
      player2ExilePile: cardFlipState.player2?.exilePile || [],
      
      lastSyncTime: cardFlipState.lastUpdate || 0
    };
  };

  // Get the current player properties
  const getCurrentPlayerProps = () => {
    const state = getStateFromGame();
    const currentPlayerNum = parseInt(selectedPlayer, 10);

    if (currentPlayerNum === 1) {
      return {
        deck: state.player1Deck,
        discardPile: state.player1DiscardPile,
        currentCards: state.player1CurrentCards,
        handCards: state.player1HandCards,
        exilePile: state.player1ExilePile,
        label: game?.attrs?.player1Label || 'Player 1'
      };
    } else {
      return {
        deck: state.player2Deck,
        discardPile: state.player2DiscardPile,
        currentCards: state.player2CurrentCards,
        handCards: state.player2HandCards,
        exilePile: state.player2ExilePile,
        label: game?.attrs?.player2Label || 'Player 2'
      };
    }
  };

  // Handle using the selected hand card
  const processUseSelectedCardAction = () => {
    if (selectedHandCardIndex === -1) return;

    // Get the current state from game attributes
    const cardFlipState = game?.attrs?.cardFlipState;
    if (!cardFlipState) return;
    
    const currentPlayerNum = parseInt(selectedPlayer, 10);
    const playerKey = `player${currentPlayerNum}`;
    
    // Get the current player's state
    const playerDeck = cardFlipState[playerKey]?.deck || [];
    const playerDiscard = cardFlipState[playerKey]?.discardPile || [];
    const playerCurrentCards = cardFlipState[playerKey]?.currentCards || [];
    const playerHandCards = cardFlipState[playerKey]?.handCards || [];
    const playerExilePile = cardFlipState[playerKey]?.exilePile || [];
    
    // Get the selected card from hand
    const selectedCard = playerHandCards[selectedHandCardIndex];
    if (!selectedCard) return;
    
    // Create new arrays for the updated state
    const newHandCards = playerHandCards.filter((_, index) => index !== selectedHandCardIndex);
    const newDiscardPile = [...playerDiscard, ...playerCurrentCards, selectedCard];
    
    // Create the updated state for the current player
    const updatedPlayerState = {
      deck: playerDeck,
      discardPile: newDiscardPile,
      currentCards: [], // Clear current cards
      handCards: newHandCards,
      exilePile: playerExilePile
    };
    
    // Create the updated game state
    const updatedCardFlipState = {
      ...cardFlipState,
      [playerKey]: {
        ...cardFlipState[playerKey],
        ...updatedPlayerState
      },
      lastUpdate: Date.now()
    };
    
    // Update the game state
    if (game && game.update) {
      game.update({
        ...game.attrs,
        cardFlipState: updatedCardFlipState
      });
      
      // Dispatch an event to notify CardFlipDialog
      if (window.wtApp) {
        const event = new CustomEvent('handViewerCardUse', { 
          detail: { 
            sourceWidget: true,
            timestamp: Date.now(),
            critical: true,
            playerNum: currentPlayerNum,
            action: 'useCard',
            currentCards: [],
            discardPile: newDiscardPile,
            handCards: newHandCards,
            preserveHandCards: true,
            selectedHandCardIndex: -1, // Reset selection
            selectionSource: null
          } 
        });
        window.wtApp.dispatchEvent(event);
        
        console.log("HandViewer: dispatched useCard event", event.detail);
      }
      
      // Call the parent's onUseHandCard function
      if (onUseHandCard) {
        onUseHandCard();
      }
    }
  };

  // Handle button click for using the selected hand card
  const handleUseSelectedCardClick = () => {
    if (selectedHandCardIndex !== -1) {
      processUseSelectedCardAction();
    }
  };

  // Initialize wtApp if it doesn't exist
  useEffect(() => {
    if (!window.wtApp) {
      window.wtApp = new EventTarget();
    }
  }, []);

  return (
    <Paper
      elevation={3}
      style={{
        position: 'fixed',
        // Always position at the bottom of the screen
        bottom: 20,
        left: '50%',
        transform: 'translateX(66.66%)',
        padding: '8px',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        minWidth: '420px',
        maxWidth: '95%',
        transition: 'all 0.3s ease',
        height: minimized || handCards.length === 0 ? '48px' : '190px',
        overflow: 'hidden',
        zIndex: 1000,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: minimized || handCards.length === 0 ? 0 : '8px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" style={{ fontSize: '0.95rem' }}>
            {playerLabel}'s Hand ({handCards.length})
          </Typography>
        </div>
        <IconButton 
          size="small" 
          onClick={onToggleMinimize}
          disabled={handCards.length === 0} // Disable toggle button if hand is empty
          title={handCards.length === 0 ? "No cards in hand" : "Toggle minimize"}
        >
          {minimized || handCards.length === 0 ? <MaximizeIcon /> : <MinimizeIcon />}
        </IconButton>
      </div>

      {!(minimized || handCards.length === 0) && (
        <div style={{ position: 'relative' }}>
          <FannedCards
            cards={handCards}
            cardSource="hand"
            isOpponent={false}
            onCardClick={onCardClick}
            selectedIndex={selectedHandCardIndex}
            emptyMessage={`${playerLabel}'s hand is empty`}
            scale={0.30}
          />
          
          {selectedHandCardIndex !== -1 && onUseHandCard && (
            <Button
              color="warning"
              variant="contained"
              onClick={handleUseSelectedCardClick}
              size="small"
              style={{ 
                position: 'absolute', 
                bottom: '4px', 
                left: '50%', 
                transform: 'translateX(-50%)',
                zIndex: 2000
              }}
            >
              Use Selected Hand Card
            </Button>
          )}
        </div>
      )}
    </Paper>
  );
};

export default HandViewer;