import { shuffleDeck } from './cardUtils';

/**
 * Handles card flipping logic with various scenarios
 * @param {Object} playerProps Current player properties
 * @param {number} cardsToFlip Number of cards to flip
 * @param {Function} onLog Function to log events
 * @returns {Object} Updated deck, discard and current cards
 */
export const handleFlipCards = (playerProps, cardsToFlip, onLog) => {
    const { deck, discardPile, currentCards, label } = playerProps;
    
    // Check if flipping would exceed 4 cards
    if (currentCards.length + cardsToFlip > 4) {
        return null;
    }

    // If deck is completely empty, automatically reshuffle
    if (deck.length === 0) {
        if (discardPile.length === 0) {
            throw new Error("No cards left in deck or discard pile.");
        }

        // Only reshuffle cards from the discard pile
        const shuffledCards = shuffleDeck([...discardPile]);
        onLog(`${label}'s discard pile was automatically reshuffled into the deck.`);
        
        return {
            newState: {
                deck: shuffledCards,
                discardPile: [],
                currentCards: currentCards
            },
            needsSecondPass: true
        };
    }

    // Handle partial deck scenario
    if (deck.length < cardsToFlip) {
        const availableCards = [...deck];
        const cardsRemaining = cardsToFlip - availableCards.length;

        if (discardPile.length === 0) {
            onLog(`${label} flipped ${availableCards.length} card(s) (all that were left in deck).`);
            return {
                newState: {
                    deck: [],
                    discardPile: discardPile,
                    currentCards: [...currentCards, ...availableCards]
                },
                needsSecondPass: false
            };
        }

        // Only reshuffle cards from the discard pile
        const shuffledCards = shuffleDeck([...discardPile]);
        onLog(`${label} flipped ${availableCards.length} card(s) and reshuffled the discard pile.`);
        
        return {
            newState: {
                deck: shuffledCards,
                discardPile: [],
                currentCards: [...currentCards, ...availableCards]
            },
            needsSecondPass: true,
            cardsRemaining
        };
    }

    // Normal case - enough cards in deck
    const newDeck = [...deck];
    const flippedCards = newDeck.splice(0, cardsToFlip);
    onLog(`${label} flipped ${cardsToFlip} card${cardsToFlip > 1 ? 's' : ''}`);

    return {
        newState: {
            deck: newDeck,
            discardPile: discardPile,
            currentCards: [...currentCards, ...flippedCards]
        },
        needsSecondPass: false
    };
};

/**
 * Handles drawing cards to hand with various scenarios
 * @param {Object} playerProps Current player properties
 * @param {number} cardsToDraw Number of cards to draw
 * @param {Function} onLog Function to log events
 * @returns {Object} Updated state with new hand cards
 */
export const handleDrawCardsToHand = (playerProps, cardsToDraw, onLog) => {
    const { deck, discardPile, currentCards, handCards, label } = playerProps;

    if (deck.length === 0) {
        if (discardPile.length === 0 && currentCards.length === 0) {
            throw new Error("No cards left in deck or discard pile.");
        }

        const allCards = [...discardPile, ...currentCards];
        const shuffledCards = shuffleDeck(allCards);
        onLog(`${label}'s cards were automatically reshuffled into the deck.`);

        return {
            newState: {
                deck: shuffledCards,
                discardPile: [],
                currentCards: [],
                handCards: handCards
            },
            needsSecondPass: true
        };
    }

    if (deck.length < cardsToDraw) {
        const availableCards = [...deck];
        const newHandCards = [...handCards, ...availableCards];
        onLog(`${label} drew ${availableCards.length} card(s) to hand (all that were left in deck).`);

        return {
            newState: {
                deck: [],
                discardPile: discardPile,
                currentCards: currentCards,
                handCards: newHandCards
            },
            needsSecondPass: false,
            newHandCards
        };
    }

    const newDeck = [...deck];
    const drawnCards = newDeck.splice(0, cardsToDraw);
    const newHandCards = [...handCards, ...drawnCards];
    onLog(`${label} drew ${cardsToDraw} card(s) to hand.`);

    return {
        newState: {
            deck: newDeck,
            discardPile: discardPile,
            currentCards: currentCards,
            handCards: newHandCards
        },
        needsSecondPass: false,
        newHandCards
    };
};

/**
 * Handles using a selected card (either from current cards or hand)
 * @param {Object} playerProps Current player properties
 * @param {number} selectedCardIndex Index of selected card in current cards
 * @param {number} selectedHandCardIndex Index of selected card in hand
 * @param {string} selectionSource Source of selection ('current' or 'hand')
 * @param {Function} onLog Function to log events
 * @returns {Object} Updated state
 */
export const handleUseSelectedCard = (playerProps, selectedCardIndex, selectedHandCardIndex, selectionSource, onLog) => {
    // Safely extract properties with defaults to prevent undefined errors
    const { 
        currentCards = [], 
        handCards = [], 
        discardPile = [], 
        deck = [], 
        label = 'Player' 
    } = playerProps || {};

    // Safety check for required parameters
    if (!playerProps) {
        console.error('Invalid playerProps for handleUseSelectedCard', { playerProps });
        return null;
    }
    
    // Check if we have a valid card to use based on the selection source
    if (selectionSource === 'current') {
        // For current cards, we need a valid selectedCardIndex
        if (selectedCardIndex === null || selectedCardIndex === undefined || 
            selectedCardIndex < 0 || selectedCardIndex >= currentCards.length) {
            console.error('Invalid current card index', { selectedCardIndex, currentCardsLength: currentCards.length });
            return null;
        }
    } else if (selectionSource === 'hand') {
        // For hand cards, we need a valid selectedHandCardIndex
        if (selectedHandCardIndex === null || selectedHandCardIndex === undefined || 
            selectedHandCardIndex < 0 || selectedHandCardIndex >= handCards.length) {
            console.error('Invalid hand card index', { selectedHandCardIndex, handCardsLength: handCards.length });
            return null;
        }
    } else {
        console.error('Invalid selection source', { selectionSource });
        return null;
    }

    if (selectionSource === 'current') {
        const selectedCard = currentCards[selectedCardIndex];
        const otherCards = currentCards.filter((_, index) => index !== selectedCardIndex);
        // Combine other flipped cards and the selected card for the discard pile
        const newDiscardPile = [...discardPile, ...otherCards, selectedCard];
        if (onLog) onLog(`${label} used: ${selectedCard.label} from current cards`);

        return {
            newState: {
                deck: deck,
                discardPile: newDiscardPile,
                currentCards: [], // Clear current cards
                handCards: handCards
            }
        };
    } else if (selectionSource === 'hand') {
        const selectedCard = handCards[selectedHandCardIndex];
        const newHandCards = handCards.filter((_, index) => index !== selectedHandCardIndex);
        // Combine current flipped cards and the selected hand card for the discard pile
        const newDiscardPile = [...discardPile, ...currentCards, selectedCard];
        if (onLog) onLog(`${label} used: ${selectedCard.label} from hand`);

        return {
            newState: {
                deck: deck,
                discardPile: newDiscardPile,
                currentCards: [], // Also clear current cards here
                handCards: newHandCards
            },
            newHandCards // Still need to return this for state update
        };
    }

    return null;
};

/**
 * Handles the logic for revealing cards
 * @param {Object} state Current component state
 * @param {number} cardsToReveal Number of cards to reveal
 * @param {boolean} revealFromOpponent Whether to reveal from opponent
 * @param {Function} onLog Function to log events
 * @param {boolean} isSharedReveal Whether this is a shared reveal (both players can see)
 * @returns {Object} Updated state with revealed cards
 */
export const handleRevealCards = (state, cardsToReveal, revealFromOpponent, onLog, isSharedReveal = false) => {
    const currentPlayerNum = state.currentPlayer;
    const targetPlayerNum = revealFromOpponent ? (currentPlayerNum === 1 ? 2 : 1) : currentPlayerNum;
    const currentPlayerLabel = targetPlayerNum === currentPlayerNum ? 'their' : state[`player${targetPlayerNum === 1 ? 1 : 2}Label`] || `Player ${targetPlayerNum}`;

    const sourceDeck = [...state[`player${targetPlayerNum}Deck`]];

    if (sourceDeck.length === 0) {
        throw new Error("No cards left in deck to reveal.");
    }

    const numCardsToReveal = Math.min(cardsToReveal, sourceDeck.length);
    const revealedCards = sourceDeck.splice(0, numCardsToReveal);
    
    // Adjust logging message based on the type of reveal
    const revealType = isSharedReveal ? 'revealed' : 'looked at';
    onLog(`${state[`player${currentPlayerNum === 1 ? 1 : 2}Label`] || `Player ${currentPlayerNum}`} ${revealType} ${numCardsToReveal} cards from ${revealFromOpponent ? currentPlayerLabel : 'their'} deck`);

    // If it's a shared reveal, we might want to store this information differently
    // or have additional game state updates, but for now we'll just track it and display differently
    return {
        newState: {
            [`player${targetPlayerNum}Deck`]: sourceDeck,
            [`player${targetPlayerNum}RevealedCards`]: revealedCards,
            revealedCardSelections: new Array(revealedCards.length).fill('discard'),
            isSharedReveal: isSharedReveal
        }
    };
};