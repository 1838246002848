import React, { Fragment } from 'react';

const plantExplosivesElements = [
  // 5 explosive tokens above the map
  {
    type: "strategyMarker",
    x: 2,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 4,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 6,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 8,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 10,
    y: -2,
    immobile: false,
    color: "#ff0000",
    label: "Explosive"
  },
  // 5 explosive tokens below the map
  {
    type: "strategyMarker",
    x: 26,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 28,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 30,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 32,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  },
  {
    type: "strategyMarker",
    x: 34,
    y: 38,
    immobile: false,
    color: "#0000ff",
    label: "Explosive"
  }
];

const raidTheVaultsElements = [
  {
    // Objective 1 on center line, 4" left of center
    type: "strategyMarker",
    x: 18 - 4,
    y: 18,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 2 on center line, 4" right of center
    type: "strategyMarker",
    x: 18 + 4,
    y: 18,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 3 - Upper Left Quadrant
    type: "strategyMarker",
    x: 9,
    y: 9,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 4 - Upper Right Quadrant
    type: "strategyMarker",
    x: 27,
    y: 9,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 5 - Lower Left Quadrant
    type: "strategyMarker",
    x: 9,
    y: 27,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 6 - Lower Right Quadrant
    type: "strategyMarker",
    x: 27,
    y: 27,
    label: "Strategy - Blocking/Impassable"
  }
];

// Corner deployment version of raid the vaults elements
const cornerRaidTheVaultsElements = [
  {
    // Objective 1 on diagonal line, 4" from center (bottom-left to top-right diagonal)
    type: "strategyMarker",
    x: 18 - 2.83, // 4 * cos(45°) = 4 * 0.7071 = 2.83
    y: 18 - 2.83, // 4 * sin(45°) = 4 * 0.7071 = 2.83
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 2 on diagonal line, 4" from center (top-left to bottom-right diagonal)
    type: "strategyMarker",
    x: 18 + 2.83, // 4 * cos(45°) = 4 * 0.7071 = 2.83
    y: 18 + 2.83, // 4 * sin(45°) = 4 * 0.7071 = 2.83
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 3 - Upper Left Quadrant
    type: "strategyMarker",
    x: 9,
    y: 9,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 4 - Upper Right Quadrant
    type: "strategyMarker",
    x: 27,
    y: 9,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 5 - Lower Left Quadrant
    type: "strategyMarker",
    x: 9,
    y: 27,
    label: "Strategy - Blocking/Impassable"
  },
  {
    // Objective 6 - Lower Right Quadrant
    type: "strategyMarker",
    x: 27,
    y: 27,
    label: "Strategy - Blocking/Impassable"
  }
];

const cloakAndDaggerElements = [
  // 2 strategy markers above the map
  {
    type: "strategyMarker",
    x: 2,
    y: -2,
    immobile: false,
    label: "Strategy - Concealing"
  },
  {
    type: "strategyMarker",
    x: 4,
    y: -2,
    immobile: false,
    label: "Strategy - Concealing"
  },
  // 2 strategy markers below the map
  {
    type: "strategyMarker",
    x: 32,
    y: 38,
    immobile: false,
    label: "Strategy - Concealing"
  },
  {
    type: "strategyMarker",
    x: 34,
    y: 38,
    immobile: false,
    label: "Strategy - Concealing"
  }
];

const stuffTheBallotsElements = [
  // Two on the centerline, each touching a different map edge
  {
    type: "strategyBallot",
    x: .585,
    y: 18
  },
  {
    type: "strategyBallot",
    x: 35.415,
    y: 18
  },
  // One in the center of each table quarter
  {
    type: "strategyBallot",
    x: 9,
    y: 9
  },
  {
    type: "strategyBallot",
    x: 27,
    y: 9
  },
  {
    type: "strategyBallot",
    x: 9,
    y: 27
  },
  {
    type: "strategyBallot",
    x: 27,
    y: 27
  },
  // One on the centerpoint of the map
  {
    type: "strategyBallot",
    x: 18,
    y: 18
  }
];

// Corner deployment version of stuff the ballots elements
const cornerStuffTheBallotsElements = [
  // Two in the corners instead of on centerline edges
  {
    type: "strategyBallot",
    x: 35.415, // Upper right corner
    y: 0.585
  },
  {
    type: "strategyBallot",
    x: 0.585, // Lower left corner
    y: 35.415
  },
  // One in the center of each table quarter
  {
    type: "strategyBallot",
    x: 9,
    y: 9
  },
  {
    type: "strategyBallot",
    x: 27,
    y: 9
  },
  {
    type: "strategyBallot",
    x: 9,
    y: 27
  },
  {
    type: "strategyBallot",
    x: 27,
    y: 27
  },
  // One on the centerpoint of the map
  {
    type: "strategyBallot",
    x: 18,
    y: 18
  }
];

const standardDeployElements = [
  {
    // Line 8" from the top
    type: "Line",
    x: 18,
    y: 8,
    width: 36,
    stroke: "#ffffff",
  },
  {
    // Line 8" from the bottom (at y=28)
    type: "Line",
    x: 18,
    y: 28,
    width: 36,
    stroke: "#ffffff",
  },
  {
    // Line in the middle
    type: "Line",
    x: 18,
    y: 18,
    width: 36,
    stroke: "#ffffff",
  },
  {
    // Circle in the middle, 6" diameter (3" radius)
    type: "Circle",
    x: 18,
    y: 18,
    radius: 6,
    stroke: "#ffffff",
    fill: "transparent"
  },
];

const cornerDeployElements = [
  {
    // Quarter circle in top left corner, 12" radius
    type: "Arc",
    x: 0,
    y: 0,
    radius: 12,
    startAngle: 0,
    endAngle: 90,
    stroke: "#ffffff",
    fill: "transparent"
  },
  {
    // Quarter circle in top right corner, 12" radius
    type: "Arc",
    x: 36,
    y: 0,
    radius: 12,
    startAngle: 90,
    endAngle: 180,
    stroke: "#ffffff",
    fill: "transparent"
  },
  {
    // Quarter circle in bottom left corner, 12" radius
    type: "Arc",
    x: 0,
    y: 36,
    radius: 12,
    startAngle: 270,
    endAngle: 360,
    stroke: "#ffffff",
    fill: "transparent"
  },
  {
    // Quarter circle in bottom right corner, 12" radius
    type: "Arc",
    x: 36,
    y: 36,
    radius: 12,
    startAngle: 180,
    endAngle: 270,
    stroke: "#ffffff",
    fill: "transparent"
  },
  {
    // Diagonal line from top-left to bottom-right
    type: "Line",
    x: 18,
    y: 18,
    width: 50.91, // √(36² + 36²) = 50.91
    rotation: 45,
    stroke: "#ffffff",
  },
  {
    // Diagonal line from bottom-left to top-right
    type: "Line",
    x: 18,
    y: 18,
    width: 50.91, // √(36² + 36²) = 50.91
    rotation: 135,
    stroke: "#ffffff",
  }
];

const wedgeDeployElements = [
  {
    // Line in the middle (centerline)
    type: "Line",
    x: 18,
    y: 18,
    width: 36,
    stroke: "#ffffff",
  },
  {
    // Top wedge - line from top center (12" from edge) to top-left corner
    type: "Line",
    x: 9, // Midpoint between (0,0) and (18,12)
    y: 6, 
    width: 21.63, // Distance from (0,0) to (18,12) = √(18² + 12²) = √(324 + 144) = √468 ≈ 21.63
    rotation: 33.69, // Mirror of bottom wedge rotation (326.31°)
    stroke: "#ffffff",
  },
  {
    // Top wedge - line from top center (12" from edge) to top-right corner
    type: "Line",
    x: 27, // Midpoint between (18,12) and (36,0)
    y: 6,
    width: 21.63, // Distance from (18,12) to (36,0) = √(18² + 12²) = √468 ≈ 21.63
    rotation: 146.31, // Mirror of bottom wedge rotation (213.69°)
    stroke: "#ffffff",
  },
  {
    // Bottom wedge - line from bottom center (12" from edge) to bottom-left corner
    type: "Line",
    x: 9, // Midpoint between (0,36) and (18,24)
    y: 30,
    width: 21.63, // Distance from (0,36) to (18,24) = √(18² + 12²) = √468 ≈ 21.63
    rotation: 326.31, // 360° - 33.69° = 326.31°
    stroke: "#ffffff",    
  },
  {
    // Bottom wedge - line from bottom center (12" from edge) to bottom-right corner
    type: "Line",
    x: 27, // Midpoint between (18,24) and (36,36)
    y: 30,
    width: 21.63, // Distance from (18,24) to (36,36) = √(18² + 12²) = √468 ≈ 21.63
    rotation: 213.69, // 180° + 33.69° = 213.69°
    stroke: "#ffffff",
  }
];

const flankDeployElements = [
  {
    // Diagonal line from top-left to bottom-right (dividing the map into quarters)
    type: "Line",
    x: 18,
    y: 18,
    width: 50.91, // √(36² + 36²) = 50.91
    rotation: 45,
    stroke: "#ffffff",
  },
  {
    // Diagonal line from bottom-left to top-right (dividing the map into quarters)
    type: "Line",
    x: 18,
    y: 18,
    width: 50.91, // √(36² + 36²) = 50.91
    rotation: 135,
    stroke: "#ffffff",
  },
  // Top center vertical line (green)
  {
    type: "Line",
    x: 18, // Center of the map horizontally
    y: 4.5, // Center point of a 9" line from top edge
    width: 9, // Length of line
    rotation: 90,
    stroke: "#ffffff",
  },
  // Bottom center vertical line (green)
  {
    type: "Line",
    x: 18, // Center of the map horizontally
    y: 31.5, // Center point of a 9" line from bottom edge
    width: 9, // Length of line
    rotation: 90,
    stroke: "#ffffff",
  },
  // Left center horizontal line (green)
  {
    type: "Line",
    x: 4.5, // Center point of a 9" line from left edge
    y: 18, // Center of the map vertically
    width: 9, // Length of line
    rotation: 0,
    stroke: "#ffffff",
  },
  // Right center horizontal line (green)
  {
    type: "Line",
    x: 31.5, // Center point of a 9" line from right edge
    y: 18, // Center of the map vertically
    width: 9, // Length of line
    rotation: 0,
    stroke: "#ffffff",
  },
  // Blue square in the center - top horizontal line
  {
    type: "Line",
    x: 18, // Center of the map horizontally
    y: 9, // 9" from top edge
    width: 18, // Width of the line (from 9" to 27")
    rotation: 0,
    stroke: "#ffffff",
  },
  // Blue square in the center - bottom horizontal line
  {
    type: "Line",
    x: 18, // Center of the map horizontally
    y: 27, // 9" from bottom edge
    width: 18, // Width of the line (from 9" to 27")
    rotation: 0,
    stroke: "#ffffff",
  },
  // Blue square in the center - left vertical line
  {
    type: "Line",
    x: 9, // 9" from left edge
    y: 18, // Center of the map vertically
    width: 18, // Height of the line (from 9" to 27")
    rotation: 90,
    stroke: "#ffffff",
  },
  // Blue square in the center - right vertical line
  {
    type: "Line",
    x: 27, // 9" from right edge
    y: 18, // Center of the map vertically
    width: 18, // Height of the line (from 9" to 27")
    rotation: 90,
    stroke: "#ffffff",
  }
];

export default [
  {
    section: 'Malfaux Scenarios',
    description: (
      <Fragment>
        GG4 scenarios for Malifaux.
      </Fragment>
    ),
    scenarios: [
      {
        name: "Raid the Vaults - Standard",
        elements: [
          ...standardDeployElements,
          ...raidTheVaultsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Raid the Vaults - Corner",
        elements: [
          ...cornerDeployElements,
          ...cornerRaidTheVaultsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Plant Explosives - Standard",
        elements: [
          ...standardDeployElements,
          ...plantExplosivesElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Plant Explosives - Corner",
        elements: [
          ...cornerDeployElements,
          ...plantExplosivesElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Cloak and Dagger - Standard",
        elements: [
          ...standardDeployElements, ,
          ...cloakAndDaggerElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Cloak and Dagger - Corner",
        elements: [
          ...cornerDeployElements,
          ...cloakAndDaggerElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Stuff the Ballots - Standard",
        elements: [
          ...standardDeployElements,
          ...stuffTheBallotsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Stuff the Ballots - Corner",
        elements: [
          ...cornerDeployElements,
          ...cornerStuffTheBallotsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Raid the Vaults - Wedge",
        elements: [
          ...wedgeDeployElements,
          ...raidTheVaultsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Plant Explosives - Wedge",
        elements: [
          ...wedgeDeployElements,
          ...plantExplosivesElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Cloak and Dagger - Wedge",
        elements: [
          ...wedgeDeployElements,
          ...cloakAndDaggerElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Stuff the Ballots - Wedge",
        elements: [
          ...wedgeDeployElements,
          ...stuffTheBallotsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Raid the Vaults - Flank",
        elements: [
          ...flankDeployElements,
          ...cornerRaidTheVaultsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Plant Explosives - Flank",
        elements: [
          ...flankDeployElements,
          ...plantExplosivesElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Cloak and Dagger - Flank",
        elements: [
          ...flankDeployElements,
          ...cloakAndDaggerElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      },
      {
        name: "Stuff the Ballots - Flank",
        elements: [
          ...flankDeployElements,
          ...cornerStuffTheBallotsElements
        ],
        image: '/Warmahordes/scenarios/quadrant_control.jpg',
      }
    ],
  }
];
