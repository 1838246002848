import React, { Fragment } from 'react';

export default [
  {
    label: 'Standard Maps',
    credit: (
      <Fragment>
        These maps are designed for Malifaux Third Edition games.
      </Fragment>
    ),
    maps: [
      {
        label: 'Bayou Swamp',
        imageUrl: '/Malifaux/maps/bayou-swamp.png',
        gameId: '-ONBYK09kIDgVFUftc4C'
      },      
    ]
  }  
];
