import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import RefreshIcon from '@mui/icons-material/Refresh';
import FlipIcon from '@mui/icons-material/FlipToFront';

import FannedCards from './FannedCards';
import { 
  handleFlipCards, 
  handleUseSelectedCard 
} from '../utils/cardActions';
import { 
  getCurrentPlayerProps as getPlayerProps, 
  getOpponentPlayerProps as getOpponentProps,
  createGameStateForSync 
} from '../utils/cardStateManager';

const CardFlipWidget = ({
  minimized = false,
  onToggleMinimize,
  game,
  onLog
}) => {
  // State
  const [state, setState] = useState({
    // Player deck states
    player1Deck: [],
    player1DiscardPile: [],
    player1CurrentCards: [],
    player1HandCards: [],
    player1ExilePile: [],
    
    player2Deck: [],
    player2DiscardPile: [],
    player2CurrentCards: [],
    player2HandCards: [],
    player2ExilePile: [],
    
    // UI states
    cardsToFlip: 1, // Always flip 1 card at a time now
    selectedCardIndex: -1,
    selectedHandCardIndex: -1,
    selectionSource: null,
    lastSyncTime: 0
  });
  
  // State for tracking flip operations  
  const [flipOperation, setFlipOperation] = useState({
    pendingOperation: false,
    operationData: null
  });
  
  // Get the current player from localStorage
  const selectedPlayer = localStorage.getItem(`currentPlayer_${game.id}`);
  const playerLabel = game?.attrs?.[`player${selectedPlayer}Label`] || `Player ${selectedPlayer}`;
  const opponentLabel = game?.attrs?.[`player${selectedPlayer === '1' ? '2' : '1'}Label`] || `Player ${selectedPlayer === '1' ? '2' : '1'}`;

  // Sync state to game.attrs
  const syncToGameAttrs = (newHandCards = null) => {
    if (!game || !game.update) return;
    
    // Use a small delay to ensure state changes have been processed
    setTimeout(() => {
      const now = Date.now();
      
      // IMPORTANT: We're removing the recent update check to ensure our changes are always applied
      // This is critical to prevent desynchronization with CardFlipDialog
      
      const cardFlipState = createGameStateForSync(state, game, newHandCards);
      
      // Update the game state
      game.update({
        ...game.attrs,
        cardFlipState
      });
      
      // Update local sync timestamp
      setState(prevState => ({
        ...prevState,
        lastSyncTime: now
      }));

      // Dispatch an event to notify CardFlipDialog that a change has been made
      // This allows for immediate synchronization without waiting for polling
      if (window.wtApp) {
        const event = new CustomEvent('cardFlipWidgetStateChange', { 
          detail: { 
            sourceWidget: true,
            timestamp: now 
          } 
        });
        window.wtApp.dispatchEvent(event);
      }
    }, 50); // Small delay to ensure state changes are processed
  };
  
  // Retrieve state from game attrs on mount and when game attrs change
  useEffect(() => {
    // Use the function created outside of the effect
    const syncFromGameAttrsHandler = () => {
      const gameCardState = game?.attrs?.cardFlipState;
      if (!gameCardState) return;
      
      // Only update if game state is newer
      if (gameCardState.lastUpdate <= state.lastSyncTime) return;
      
      const updatedState = {
        player1Deck: gameCardState.player1?.deck || [],
        player1DiscardPile: gameCardState.player1?.discardPile || [],
        player1CurrentCards: gameCardState.player1?.currentCards || [],
        player1ExilePile: gameCardState.player1?.exilePile || [],
        
        player2Deck: gameCardState.player2?.deck || [],
        player2DiscardPile: gameCardState.player2?.discardPile || [],
        player2CurrentCards: gameCardState.player2?.currentCards || [],
        player2ExilePile: gameCardState.player2?.exilePile || [],
        
        lastSyncTime: gameCardState.lastUpdate
      };
      
      // Preserve hand cards - only update the current player's hand cards
      if (selectedPlayer === "1") {
        updatedState.player1HandCards = gameCardState.player1?.handCards || [];
        updatedState.player2HandCards = state.player2HandCards;
      } else if (selectedPlayer === "2") {
        updatedState.player2HandCards = gameCardState.player2?.handCards || [];
        updatedState.player1HandCards = state.player1HandCards;
      }
      
      setState(prevState => ({
        ...prevState,
        ...updatedState
      }));
    };

    syncFromGameAttrsHandler();
    
    // Set up regular sync
    const syncInterval = setInterval(syncFromGameAttrsHandler, 2000);
    
    return () => {
      clearInterval(syncInterval);
    };
  }, [game?.attrs?.cardFlipState?.lastUpdate, selectedPlayer, state.lastSyncTime, state.player1HandCards, state.player2HandCards]);

  // useEffect to handle flip operation side effects
  useEffect(() => {
    if (flipOperation.pendingOperation && flipOperation.operationData) {
      const { newState, needsSecondPass, currentPlayerNum } = flipOperation.operationData;
      
      // Get current hand cards to preserve them
      const currentHandCards = currentPlayerNum === 1 ? state.player1HandCards : state.player2HandCards;
      
      // Force immediate update to game state without delay - PRESERVE HAND CARDS!
      const cardFlipState = createGameStateForSync(
        {
          ...state,
          // Explicitly ensure hand cards are preserved in the state being synced
          player1HandCards: state.player1HandCards,
          player2HandCards: state.player2HandCards
        }, 
        game, 
        // Only pass current player's hand cards as newHandCards to avoid null overwrites
        currentPlayerNum === 1 ? state.player1HandCards : state.player2HandCards
      );
      
      if (game && game.update) {
        // Update the game state immediately
        const now = Date.now();
        game.update({
          ...game.attrs,
          cardFlipState: {
            ...cardFlipState,
            lastUpdate: now
          }
        });
        
        // Update local sync timestamp
        setState(prevState => ({
          ...prevState,
          lastSyncTime: now
        }));
        
        // Dispatch an event to notify CardFlipDialog with critical flag to ensure immediate handling
        if (window.wtApp) {
          const event = new CustomEvent('cardFlipWidgetStateChange', { 
            detail: { 
              sourceWidget: true,
              timestamp: now,
              critical: true, // Mark as requiring immediate handling
              playerNum: currentPlayerNum,
              action: 'flipCard',
              currentCards: newState.currentCards,
              // Include hand cards to ensure they're preserved during sync
              handCards: currentHandCards,
              preserveHandCards: true // Flag to indicate hand cards should be preserved
            } 
          });
          window.wtApp.dispatchEvent(event);
          
          console.log("CardFlipWidget: dispatched flipCard event with state:", {
            currentCards: newState.currentCards,
            handCards: currentHandCards
          });
        }
      }
      
      // Handle second pass if needed
      if (needsSecondPass) {
        setTimeout(() => {
          // Get fresh player props for second pass
          const phaseTwo = getPlayerProps(state, syncToGameAttrs, game);
          const newDeck = [...phaseTwo.deck];
          const additionalCards = newDeck.splice(0, 1); // Always just 1 card
          
          // Create update for second phase
          const secondPhaseUpdate = {};
          if (currentPlayerNum === 1) {
            secondPhaseUpdate.player1Deck = newDeck;
            secondPhaseUpdate.player1CurrentCards = [...phaseTwo.currentCards, ...additionalCards];
          } else {
            secondPhaseUpdate.player2Deck = newDeck;
            secondPhaseUpdate.player2CurrentCards = [...phaseTwo.currentCards, ...additionalCards];
          }
          
          // Update state for second phase
          setState(prevState => ({
            ...prevState,
            ...secondPhaseUpdate
          }));
          
          // Dispatch another event for the second phase
          if (window.wtApp) {
            const secondEvent = new CustomEvent('cardFlipWidgetStateChange', { 
              detail: { 
                sourceWidget: true,
                timestamp: Date.now(),
                critical: true,
                playerNum: currentPlayerNum,
                currentCards: secondPhaseUpdate[`player${currentPlayerNum}CurrentCards`]
              } 
            });
            window.wtApp.dispatchEvent(secondEvent);
          }
          
          // Log the result
          const label = currentPlayerNum === 1 ? 
            (game?.attrs?.player1Label || "Player 1") : 
            (game?.attrs?.player2Label || "Player 2");
          onLog(`${label} flipped an additional card from the reshuffled deck.`);
          
          // Sync to game attrs after everything is done
          syncToGameAttrs();
        }, 100);
      }
      
      // Reset the operation flag
      setFlipOperation({
        pendingOperation: false,
        operationData: null
      });
    }
  // Only depend on the flipOperation state to avoid circular dependencies
  }, [flipOperation]);

  // If no player is selected, don't render the component
  if (!selectedPlayer) {
    return null;
  }

  // Get props for the current player
  const getCurrentPlayerProps = () => {
    return getPlayerProps(state, syncToGameAttrs, game);
  };
  
  // Get props for the opponent
  const getOpponentPlayerProps = () => {
    return getOpponentProps(state, game);
  };
  
  // Flip cards
  const flipCards = () => {
    try {
      const playerProps = getCurrentPlayerProps();
      const result = handleFlipCards(
        playerProps,
        1, // Always flip 1 card at a time
        onLog
      );
      
      if (!result) return; // Exit if we shouldn't flip (e.g., exceeding 4 cards)
      
      const { newState, needsSecondPass, cardsRemaining } = result;
      const currentPlayerNum = parseInt(selectedPlayer, 10);
      const stateUpdate = {};
      
      // Map the generic state keys to player-specific state keys
      if (currentPlayerNum === 1) {
        stateUpdate.player1Deck = newState.deck;
        stateUpdate.player1DiscardPile = newState.discardPile;
        stateUpdate.player1CurrentCards = newState.currentCards;
      } else {
        stateUpdate.player2Deck = newState.deck;
        stateUpdate.player2DiscardPile = newState.discardPile;
        stateUpdate.player2CurrentCards = newState.currentCards;
      }
      
      // First update the state
      setState(prevState => ({
        ...prevState,
        ...stateUpdate
      }));
      
      // Then trigger the side effect via flipOperation state
      setFlipOperation({
        pendingOperation: true,
        operationData: {
          newState,
          needsSecondPass,
          currentPlayerNum
        }
      });
    } catch (error) {
      alert(error.message);
    }
  };
  
  // Select a card
  const selectCard = (index) => {
    if (state.selectedCardIndex === index && state.selectionSource === 'current') {
      setState(prevState => ({
        ...prevState,
        selectedCardIndex: -1,
        selectedHandCardIndex: -1,
        selectionSource: null
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        selectedCardIndex: index,
        selectedHandCardIndex: -1,
        selectionSource: 'current'
      }));
    }
  };
  
  // Select a hand card
  const selectHandCard = (index) => {
    if (state.selectedHandCardIndex === index && state.selectionSource === 'hand') {
      setState(prevState => ({
        ...prevState,
        selectedCardIndex: -1,
        selectedHandCardIndex: -1,
        selectionSource: null
      }));
    } else {
      setState(prevState => ({
        ...prevState,
        selectedCardIndex: -1,
        selectedHandCardIndex: index,
        selectionSource: 'hand'
      }));
    }
  };
  
  // Use selected card
  const useSelectedCard = () => {
    const result = handleUseSelectedCard(
      getCurrentPlayerProps(),
      state.selectedCardIndex,
      state.selectedHandCardIndex,
      state.selectionSource,
      onLog
    );
    
    if (!result) return;
    
    const { newState, newHandCards } = result;
    const currentPlayerNum = parseInt(selectedPlayer, 10);
    const stateUpdate = {};
    
    // If using a card from hand, also move current cards to discard
    if (state.selectionSource === 'hand') {
      const currentCards = currentPlayerNum === 1 ? state.player1CurrentCards : state.player2CurrentCards;
      
      if (currentCards.length > 0) {
        newState.discardPile = [...newState.discardPile, ...currentCards];
        newState.currentCards = []; // Clear current cards
      }
    }
    
    // Map to player-specific state keys
    if (currentPlayerNum === 1) {
      stateUpdate.player1Deck = newState.deck;
      stateUpdate.player1DiscardPile = newState.discardPile;
      stateUpdate.player1CurrentCards = newState.currentCards;
      stateUpdate.player1HandCards = newState.handCards;
    } else {
      stateUpdate.player2Deck = newState.deck;
      stateUpdate.player2DiscardPile = newState.discardPile;
      stateUpdate.player2CurrentCards = newState.currentCards;
      stateUpdate.player2HandCards = newState.handCards;
    }
    
    // First update the local state
    setState(prevState => ({
      ...prevState,
      ...stateUpdate,
      selectedCardIndex: -1,
      selectedHandCardIndex: -1,
      selectionSource: null
    }));

    // Get current hand cards for sync (using the updated hand cards from the action)
    const currentHandCards = currentPlayerNum === 1 ? newState.handCards : newState.handCards;

    // Force immediate update to game state with proper hand card preservation
    const cardFlipState = createGameStateForSync(
      {
        ...state,
        ...stateUpdate,
        // Explicitly ensure hand cards are preserved in the state being synced
        player1HandCards: currentPlayerNum === 1 ? newState.handCards : state.player1HandCards,
        player2HandCards: currentPlayerNum === 2 ? newState.handCards : state.player2HandCards
      }, 
      game, 
      // Pass the updated hand cards for the current player
      currentHandCards
    );
    
    if (game && game.update) {
      // Update the game state immediately
      const now = Date.now();
      game.update({
        ...game.attrs,
        cardFlipState: {
          ...cardFlipState,
          lastUpdate: now
        }
      });
      
      // Update local sync timestamp
      setState(prevState => ({
        ...prevState,
        lastSyncTime: now
      }));
      
      // Dispatch a critical event to notify CardFlipDialog immediately
      if (window.wtApp) {
        const event = new CustomEvent('cardFlipWidgetStateChange', { 
          detail: { 
            sourceWidget: true,
            timestamp: now,
            critical: true, // Mark as requiring immediate handling
            playerNum: currentPlayerNum,
            action: 'useCard',
            currentCards: newState.currentCards,
            discardPile: newState.discardPile,
            handCards: currentHandCards,
            preserveHandCards: true
          } 
        });
        window.wtApp.dispatchEvent(event);
        
        console.log("CardFlipWidget: dispatched useCard event with state:", {
          currentCards: newState.currentCards,
          discardPile: newState.discardPile,
          handCards: currentHandCards
        });
      }
    }
  };
  
  // Undo the last flip
  const undoLastFlip = () => {
    const { currentCards, deck, updateState, label } = getCurrentPlayerProps();
    
    if (currentCards.length === 0) {
      return;
    }
    
    // Take the last card from current cards
    const newCurrentCards = [...currentCards];
    const lastCard = newCurrentCards.pop();
    
    // Put it back on top of the deck
    const newDeck = [lastCard, ...deck];
    
    const result = updateState(newDeck, getCurrentPlayerProps().discardPile, newCurrentCards);
    
    // First update the local state
    setState(prevState => ({
      ...prevState,
      ...result.newState
    }));
    
    // Get current hand cards for sync (using the updated hand cards from the action)
    const currentPlayerNum = parseInt(selectedPlayer, 10);
    const currentHandCards = currentPlayerNum === 1 ? state.player1HandCards : state.player2HandCards;

    // Force immediate update to game state with proper hand card preservation
    const cardFlipState = createGameStateForSync(
      {
        ...state,
        ...result.newState,
        // Explicitly ensure hand cards are preserved in the state being synced
        player1HandCards: currentPlayerNum === 1 ? state.player1HandCards : state.player1HandCards,
        player2HandCards: currentPlayerNum === 2 ? state.player2HandCards : state.player2HandCards
      }, 
      game, 
      // Pass the updated hand cards for the current player
      currentHandCards
    );
    
    if (game && game.update) {
      // Update the game state immediately
      const now = Date.now();
      game.update({
        ...game.attrs,
        cardFlipState: {
          ...cardFlipState,
          lastUpdate: now
        }
      });
      
      // Update local sync timestamp
      setState(prevState => ({
        ...prevState,
        lastSyncTime: now
      }));
      
      // Dispatch a critical event to notify CardFlipDialog immediately
      if (window.wtApp) {
        const event = new CustomEvent('cardFlipWidgetStateChange', { 
          detail: { 
            sourceWidget: true,
            timestamp: now,
            critical: true, // Mark as requiring immediate handling
            playerNum: currentPlayerNum,
            action: 'undoFlip',
            currentCards: newCurrentCards,
            deck: newDeck,
            handCards: currentHandCards,
            preserveHandCards: true
          } 
        });
        window.wtApp.dispatchEvent(event);
        
        console.log("CardFlipWidget: dispatched undoFlip event with state:", {
          currentCards: newCurrentCards,
          deck: newDeck,
          handCards: currentHandCards
        });
      }
      
      // Log the action
      onLog(`${label} returned a card to the top of the deck`);
    }
  };
  
  // Get current player data
  const currentPlayerNum = parseInt(selectedPlayer, 10);
  const currentCards = currentPlayerNum === 1 ? state.player1CurrentCards : state.player2CurrentCards;
  
  // Get opponent player data
  const opponentNum = currentPlayerNum === 1 ? 2 : 1;
  const opponentCards = opponentNum === 1 ? state.player1CurrentCards : state.player2CurrentCards;
  
  // Get correct deck counts
  const deckCount = currentPlayerNum === 1 ? state.player1Deck.length : state.player2Deck.length;
  
  return (
    <Paper
      elevation={3}
      style={{
        position: 'fixed',
        // Position above the HandViewer
        bottom: (() => {
          // Get HandViewer state from game.attrs
          const handViewerMinimized = localStorage.getItem(`handViewerMinimized_${game.id}`) === 'true';
          const handCards = selectedPlayer == "1" 
            ? (game?.attrs?.cardFlipState?.player1?.handCards || []) 
            : (game?.attrs?.cardFlipState?.player2?.handCards || []);
          
          // Calculate position based on HandViewer state
          const handViewerHeight = handViewerMinimized || handCards.length === 0 ? 48 : 190;
          const padding = 10;
          
          // Position CardFlipWidget above HandViewer
          return handViewerHeight + 20 + padding; // HandViewer is at bottom 20
        })(),
        left: '50%',
        transform: 'translateX(66.66%)',
        padding: '8px',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        width: '420px',
        height: minimized ? '48px' : '230px',
        transition: 'all 0.3s ease',
        overflow: 'hidden',
        zIndex: 1000,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: minimized ? 0 : '8px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" style={{ fontSize: '0.95rem' }}>
            Card Flip Widget
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button 
            color="primary" 
            variant="contained" 
            size="small"
            onClick={flipCards}
            disabled={deckCount === 0}
            style={{ marginRight: '8px', fontSize: '0.8rem', padding: '2px 8px' }}
            startIcon={<FlipIcon />}
          >
            Flip
          </Button>
          <IconButton size="small" onClick={onToggleMinimize}>
            {minimized ? <MaximizeIcon /> : <MinimizeIcon />}
          </IconButton>
        </div>
      </div>

      {!minimized && (
        <div>
          <Divider style={{ margin: '4px 0 8px 0' }} />
          
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
            {/* Current player's cards */}
            <div style={{ flex: 1 }}>
              <Typography variant="subtitle2" style={{ 
                marginBottom: '4px', 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                fontSize: '0.8rem' /* Smaller text */
              }}>
                <span>{playerLabel}'s Current Cards ({currentCards.length})</span>
                <div>
                  {currentCards.length > 0 && (
                    <Button 
                      size="small" 
                      startIcon={<RefreshIcon style={{ fontSize: '0.9rem' }} />} /* Smaller icon */
                      onClick={undoLastFlip}
                      style={{ fontSize: '0.7rem', padding: '2px 8px' }} /* Smaller button */
                    >
                      Undo
                    </Button>
                  )}
                </div>
              </Typography>
              <div style={{ minHeight: '105px', position: 'relative' }}> {/* 30% smaller than 150px */}
                <FannedCards
                  cards={currentCards}
                  selectedIndex={state.selectionSource === 'current' ? state.selectedCardIndex : -1}
                  cardSource="current"
                  onCardClick={selectCard}
                  emptyMessage={`No cards flipped yet`}
                  scale={0.30}
                />
                
                {state.selectionSource === 'current' && state.selectedCardIndex !== -1 && currentCards.length > 0 && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={useSelectedCard}
                    size="small"
                    style={{ 
                      position: 'absolute', 
                      bottom: '4px', 
                      left: '50%', 
                      transform: 'translateX(-50%)',
                      zIndex: 2000,
                      fontSize: '0.7rem',
                      padding: '2px 8px'
                    }}
                  >
                    Use Card
                  </Button>
                )}
              </div>
            </div>
            
            <Divider orientation="vertical" flexItem />
            
            {/* Opponent's cards */}
            <div style={{ flex: 1 }}>
              <Typography variant="subtitle2" style={{ 
                marginBottom: '4px', 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                fontSize: '0.8rem'
              }}>
                <span>{opponentLabel}'s Current Cards ({opponentCards.length})</span>
                <div></div>
              </Typography>
              <div style={{ minHeight: '105px' }}> {}
                <FannedCards
                  cards={opponentCards}
                  isOpponent={true}
                  cardSource="current"
                  emptyMessage={`${opponentLabel} has no cards flipped`}
                  scale={0.30}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default CardFlipWidget;