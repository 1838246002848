import React from 'react';
import Typography from '@mui/material/Typography';

/**
 * Renders a set of cards in a fanned layout
 */
const FannedCards = ({ 
  cards, 
  isOpponent = false, 
  selectedIndex = -1, 
  cardSource = 'current',
  onCardClick = null,
  emptyMessage = "No cards available",
  scale = null
}) => {
  // Base card dimensions
  const cardHeight = 300;
  const cardWidth = 220;
  
  // Adjust scale based on the card source
  const getDefaultScale = () => {
    if (isOpponent) return 0.5;
    if (cardSource === 'hand') return 0.65;
    if (cardSource === 'exile') return 0.25;
    return 0.67;
  };
  
  const defaultScale = getDefaultScale();
  const actualScale = scale || defaultScale;
  const height = cardHeight * actualScale;
  const width = cardWidth * actualScale;
  
  // Fan settings for when we have cards
  const maxCards = cards?.length || 1; // Use at least 1 for empty state to keep consistent sizing
  const fanAngle = Math.min(8, 35 / maxCards);
  const totalAngle = (maxCards - 1) * fanAngle;
  const startAngle = -totalAngle / 2;
  
  // ALWAYS use the same container style regardless of empty or filled state
  // This ensures consistent positioning and size
  const containerStyle = {
    position: 'relative',
    height: height + 20,
    width: '100%',
    margin: '0 auto',
    maxWidth: width + (maxCards - 1) * (width * 0.25)
  };
  
  // Selection colors
  const selectionColor = cardSource === 'hand' ? '#ff9800' : '#f50057';
  const borderColor = cardSource === 'hand' ? '#ff9800' : 
                     isOpponent ? '#7b1fa2' : '#ccc';

  const handleCardClick = (index) => {
    if (onCardClick) {
      onCardClick(index);
    }
  };
  
  // If no cards, render placeholder using THE SAME container
  if (!cards || cards.length === 0) {
    return (
      <div style={containerStyle}>
        <div style={{ 
          height, 
          width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px dashed ${borderColor}`,
          borderRadius: 8,
          position: 'absolute',
          left: '50%',
          marginLeft: -width / 2,
          bottom: 0,
          textAlign: 'center',
        }}>
          <Typography variant="body2" color="textSecondary" style={{ 
            width: '100%', 
            padding: '0 8px',
            fontSize: `${0.8 * (actualScale / defaultScale)}rem`
          }}>{emptyMessage}</Typography>
        </div>
      </div>
    );
  }
  
  return (
    <div style={containerStyle}>
      {cards.map((card, index) => {
        const rotateAngle = startAngle + (index * fanAngle);
        const horizontalShift = rotateAngle * 3.5;
        const isSelected = selectedIndex === index;
        
        const cardStyle = {
          position: 'absolute',
          maxWidth: width,
          width: '100%',
          transform: `rotate(${rotateAngle}deg) translateX(${horizontalShift}px)`,
          transformOrigin: '50% 100%',
          transition: 'all 0.2s ease',
          left: '50%',
          marginLeft: -width / 2,
          zIndex: index + 10,
          bottom: isSelected ? 8 : 0,
          borderRadius: '8px',
          boxShadow: isSelected ? 
            `0 0 0 2px ${selectionColor}, 0 0 10px 3px ${selectionColor}99` : 
            'none',
          cursor: onCardClick ? 'pointer' : 'default',
        };
        
        // Always use the handleCardClick function if onCardClick is provided,
        // regardless of whether this is an opponent card
        const handleClick = onCardClick ? () => handleCardClick(index) : undefined;
        
        return (
          <img 
            key={`${cardSource}-${card.suit || ''}-${card.value || ''}-${index}`}
            src={card.imageUrl}
            alt={card.label || `Card ${index + 1}`}
            style={cardStyle}
            onClick={handleClick}
            className={isSelected ? `selected-card ${cardSource}-card` : `${cardSource}-card`}
          />
        );
      })}
    </div>
  );
};

export default FannedCards;
