import React, { Component, Fragment } from 'react';
import { ref, remove, onValue } from '@firebase/database';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { shareGameCopy, db } from '../../../initFirebase';
import Maps from '../data/Maps';
import Dialog from '../../../WTDialog';

class MapDialog extends Component {
  state = {
    code: '',
    error: undefined,
    sharedGameId: undefined,
  };
  
  generateCopyLink = () => {
    shareGameCopy(this.props.game.id, this.props.user.uid)
      .then(({ gameId }) => this.setState({ sharedGameId: gameId }))
      .then(() => this.copyLink());
  };
  
  clearMap = (mapHasScenario) => {
    const { connection } = this.props;
    return new Promise((resolve) => {
      onValue(
        ref(db, connection.rootPath()),
        (data) => {
          const refsToRemove = [];
          data.child('shapes').forEach((shape) => {
            const val = shape.val();
            if (mapHasScenario || !val.scenario) {
              refsToRemove.push(shape.ref);
            }
          });
          data.child('tokens').forEach((token) => {
            const val = token.val();
            if (mapHasScenario && val.scenario) {
              refsToRemove.push(token.ref);
            }
          });
          data.child('widgets').forEach((widget) => {
            const val = widget.val();
            if (['Counter', 'Token'].includes(val.widgetType)) {
              refsToRemove.push(widget.ref);
            }
          });
          Promise.all(refsToRemove.map((ref) => remove(ref))).then(resolve);
        },
        { onlyOnce: true }
      );
    });
  };
  
  randomMap = (maps) => {
    const map = maps[Math.floor(Math.random() * maps.length)];
    this.props.log(`Random map: ${map.label}`);
    this.loadGame(map.gameId);
  };
  
  selectMap = (map) => {
    this.props.log(`Selected map: ${map.label}`);
    this.loadGame(map.gameId);
  };
  
  loadGame(gameId) {
    const { connection, onClose } = this.props;
    onValue(
      ref(db, `/games/${gameId}`),
      (snapshot) => {
        const gameAttrs = snapshot.val();
        const mapHasScenario =
          Object.values(gameAttrs.shapes || {}).find(
            (shape) => shape.scenario
          ) ||
          Object.values(gameAttrs.tokens || {}).find((token) => token.scenario);
        this.clearMap(mapHasScenario).then(() => {
          this.props.game.update({
            backgroundImageUrl: gameAttrs.game.backgroundImageUrl,
          });
          Object.values(gameAttrs.shapes || {}).forEach((shape) =>
            connection.add('shape', shape)
          );
          Object.values(gameAttrs.tokens || {}).forEach((token) =>
            connection.add('token', token)
          );
          Object.values(gameAttrs.widgets || {})
            .filter((widget) =>
              ['Counter', 'Token'].includes(widget.widgetType)
            )
            .forEach((widget) => connection.add('widget', widget));
          onClose();
        });
      },
      { onlyOnce: true }
    );
  }
  
  exportMap() {
    return (
      <Fragment>
        <Typography variant="h6" gutterBottom>
          Export Map
        </Typography>
        <Typography variant="body2" paragraph>
          Get a shareable link that others can use to load your current map.
        </Typography>
        <Button
          style={{ marginBottom: 16 }}
          variant="contained"
          onClick={this.generateCopyLink}
          color="primary"
        >
          Generate Link
        </Button>
        {this.state.sharedGameId && (
          <TextField
            readOnly
            fullWidth
            value={`${window.location.protocol}//${window.location.hostname}/games/${this.state.sharedGameId}/copy`}
            id="copy-url"
          />
        )}
      </Fragment>
    );
  }
  
  copyLink = () => {
    document.getElementById('copy-url').select();
    document.execCommand('copy');
    alert('Copied');
  };
  
  render() {
    const maps = Maps;
    return (
      <Dialog open={this.props.open} maxWidth="md" onClose={this.props.onClose}>
        <DialogTitle>Load Malifaux Map</DialogTitle>
        <DialogContent>
          {maps.map((section, i) => (
            <Fragment key={i}>
              <Typography gutterBottom variant="h6">
                {section.label}
              </Typography>
              {section.credit && (
                <Typography paragraph variant="body2">
                  {section.credit}
                </Typography>
              )}
              {section.maps && section.maps.length > 1 && (
                <div>
                  <Button
                    style={{ marginBottom: 16, marginRight: 16 }}
                    variant="contained"
                    color="primary"
                    onClick={() => this.randomMap(section.maps)}
                  >
                    Random
                  </Button>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {section.maps.map((map, j) => (
                  <Card
                    key={j}
                    style={{
                      width: '33%',
                      marginRight: '2%',
                      marginBottom: 24,
                    }}
                  >
                    <CardActionArea
                      onClick={() => {
                        this.selectMap(map);
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={map.imageUrl}
                        style={{ height: 150, objectFit: 'cover' }}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="body1">
                          {map.label}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </div>
            </Fragment>
          ))}
          {this.exportMap()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default MapDialog;
