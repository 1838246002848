import React, { Component, Fragment } from 'react';

import CardsIcon from './CardsIcon';

import ActionButton from '../../../game/ActionButton';
import CardFlipDialog from './CardFlip/CardFlipDialog';

export default class extends Component {
  constructor(props) {
    super(props);
    // Initialize wtApp if it doesn't exist
    if (!window.wtApp) {
      window.wtApp = new EventTarget();
    }
  }

  // Get the current player from localStorage
  getCurrentPlayer() {
    const { game } = this.props;
    return localStorage.getItem(`currentPlayer_${game.id}`);
  }

  // Open the dialog for the current player or allow player selection inside the dialog
  openDialog = () => {
    const { game } = this.props;
    const currentPlayer = this.getCurrentPlayer();
    
    // Always open the dialog, even if no player is selected
    // The player selection will be handled inside the dialog
    
    // If a player is already selected, use player-specific attribute
    if (currentPlayer) {
      const updateObj = {};
      updateObj[`cardFlipDialogOpen_${currentPlayer}`] = true;
      
      game.update({
        ...game.attrs,
        ...updateObj
      });
    } else {
      // If no player is selected, use a generic dialog open attribute
      game.update({
        ...game.attrs,
        cardFlipDialogOpen: true
      });
    }
  }
  
  // Close the dialog for the current player or generic dialog
  closeDialog = () => {
    const { game } = this.props;
    const currentPlayer = this.getCurrentPlayer();
    
    const updateObj = {};
    
    if (currentPlayer) {
      // Close player-specific dialog
      updateObj[`cardFlipDialogOpen_${currentPlayer}`] = false;
    } else {
      // Close generic dialog
      updateObj.cardFlipDialogOpen = false;
    }
    
    game.update({
      ...game.attrs,
      ...updateObj
    });
  }
  
  render() {
    const { game } = this.props;
    const currentPlayer = this.getCurrentPlayer();
    
    // Check if the dialog should be open for the current player or generically
    const isDialogOpen = currentPlayer ? 
      !!game.attrs[`cardFlipDialogOpen_${currentPlayer}`] : !!game.attrs.cardFlipDialogOpen;
    
    return (
      <Fragment>
        <ActionButton
          icon={CardsIcon}
          primary="Card Flip"
          onClick={this.openDialog}
        />
        <CardFlipDialog
          onLog={this.props.log}
          game={game}
          open={isDialogOpen}
          onClose={this.closeDialog}
        />
      </Fragment>
    );
  }
}
