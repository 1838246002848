import React, { useState } from 'react';
import ImportCrewDialog from './ImportCrewDialog';
import ActionButton from '../../../game/ActionButton';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const ImportCrewAction = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <ActionButton
        icon={GroupAddIcon}
        onClick={handleOpenDialog}
        primary="Import Crew"
      />
      
      <ImportCrewDialog 
        open={dialogOpen} 
        onClose={handleCloseDialog} 
        game={props.game}
        onLog={props.log}
        onAddToken={props.onAddToken}
        connection={props.connection}
      />
    </>
  );
};

export default ImportCrewAction;
