import ScenarioAction from './ScenarioAction';
import CardFlipAction from './CardFlipAction';
import ImportCrewAction from './ImportCrewAction';
import LoadMapAction from './LoadMapAction';

export default [
  CardFlipAction,  
  ScenarioAction,
  ImportCrewAction,
  LoadMapAction,  
];
