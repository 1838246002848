import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import models from '../data/models';
import modelToToken from './modelToToken';
import modelNameMapping from './modelNameMapping';

const ImportCrewDialog = ({ open, onClose, game, onLog, onAddToken, connection }) => {
  const [crewText, setCrewText] = useState('');
  const [parsedCrew, setParsedCrew] = useState(null);
  const [error, setError] = useState('');
  const [availableModels, setAvailableModels] = useState([]);
  const [crewColor, setCrewColor] = useState('#' + Math.floor(Math.random() * 16777215).toString(16));

  // Load models when component mounts
  useEffect(() => {
    setAvailableModels(models);
  }, []);

  // Parse the crew list text
  const parseCrew = (text) => {
    try {
      setError('');

      // Initialize crew data structure
      const crew = {
        name: '',
        faction: '',
        size: 0,
        pool: 0,
        leader: null,
        totems: [],
        hires: [],
        references: []
      };

      // Split the text into lines
      const lines = text.split('\n').filter(line => line.trim() !== '');

      // Parse the first line for crew name and faction
      const crewNameMatch = lines[0].match(/^(.*?)\s*\((.*?)\)$/);
      if (crewNameMatch) {
        crew.name = crewNameMatch[1].trim();
        crew.faction = crewNameMatch[2].trim();
      } else {
        crew.name = lines[0].trim();
      }

      // Parse the second line for size and pool
      const sizePoolMatch = lines[1].match(/Size:\s*(\d+).*Pool:\s*(\d+)/i);
      if (sizePoolMatch) {
        crew.size = parseInt(sizePoolMatch[1], 10);
        crew.pool = parseInt(sizePoolMatch[2], 10);
      }

      // Parse the rest of the lines
      let currentSection = '';
      for (let i = 2; i < lines.length; i++) {
        const line = lines[i].trim();

        // Check if this is a section header
        if (line.endsWith(':')) {
          currentSection = line.slice(0, -1).toLowerCase();
          continue;
        }

        // Add the model to the appropriate section
        switch (currentSection) {
          case 'leader':
            crew.leader = line;
            break;
          case 'totem(s)':
            crew.totems.push(line);
            break;
          case 'hires':
            crew.hires.push(line);
            break;
          case 'references':
            crew.references.push(line);
            break;
        }
      }

      setParsedCrew(crew);
      return crew;
    } catch (err) {
      console.error('Error parsing crew list:', err);
      setError('Failed to parse crew list. Please check the format and try again.');
      setParsedCrew(null);
      return null;
    }
  };

  // Handle text change in the textarea
  const handleTextChange = (e) => {
    setCrewText(e.target.value);
    if (e.target.value.trim()) {
      parseCrew(e.target.value);
    } else {
      setParsedCrew(null);
    }
  };

  // Find the best matching model from the available models
  const findBestModelMatch = (modelName) => {
    // Clean the model name for better matching
    // First, remove any trailing spaces followed by numbers (e.g., "Model 2" becomes "Model")
    const nameWithoutTrailingNumbers = modelName.trim().replace(/\s+\d+$/, '');
    const cleanName = nameWithoutTrailingNumbers.toLowerCase();

    // Try to find a direct match with the original name
    const directMatch = availableModels.find(m =>
      m.name.toLowerCase() === cleanName ||
      (m.alias && m.alias.toLowerCase() === cleanName)
    );

    if (directMatch) {
      return directMatch;
    }

    // Try to find a partial match (name contains the search term)
    const partialMatches = availableModels.filter(m =>
      m.name.toLowerCase().includes(cleanName) ||
      (m.alias && m.alias.toLowerCase().includes(cleanName))
    );

    if (partialMatches.length > 0) {
      // Sort by name length to find the closest match
      partialMatches.sort((a, b) => a.name.length - b.name.length);
      return partialMatches[0];
    }

    return null;
  };

  // Add a model to the game
  const addModelToGame = (modelName, modelType, faction) => {
    if (!game) {
      return null;
    }

    // Try to find a matching model
    const matchedModel = findBestModelMatch(modelName);

    // Generate a position near the center of the board
    const centerX = game.attrs.width / 2;
    const centerY = game.attrs.height / 2;
    const randomOffset = () => (Math.random() - 0.5) * 10;

    let tokenAttrs;

    if (matchedModel) {
      // Use the matched model to create token attributes
      tokenAttrs = Object.assign({}, modelToToken(matchedModel), {
        x: centerX + randomOffset(),
        y: centerY + randomOffset(),
        facing: 0,
        color: crewColor // Use the same color for all models in the crew
      });
    }

    // Add the token to the game
    try {
      if (onAddToken) {
        onAddToken(tokenAttrs);
        return tokenAttrs;
      } else if (connection) {
        connection.addToken(tokenAttrs);
        return tokenAttrs;
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Error adding model ${modelName}:`, error);
      return null;
    }
  };

  // Import the entire crew to the game
  const importCrew = () => {
    if (!parsedCrew) {
      return;
    }

    // Generate a single color for the entire crew
    setCrewColor('#' + Math.floor(Math.random() * 16777215).toString(16));

    try {
      // Track all added models
      const addedModels = [];

      // Add leader
      if (parsedCrew.leader) {
        const leaderToken = addModelToGame(parsedCrew.leader, 'Master', parsedCrew.faction);
        if (leaderToken) addedModels.push(leaderToken);
      }

      // Add totems
      if (parsedCrew.totems && parsedCrew.totems.length > 0) {
        parsedCrew.totems.forEach(totem => {
          const totemToken = addModelToGame(totem, 'Totem', parsedCrew.faction);
          if (totemToken) addedModels.push(totemToken);
        });
      }

      // Add hires
      if (parsedCrew.hires && parsedCrew.hires.length > 0) {
        parsedCrew.hires.forEach(hire => {
          const hireToken = addModelToGame(hire, 'Minion', parsedCrew.faction);
          if (hireToken) addedModels.push(hireToken);
        });
      }

      // Log the import
      const message = `Imported crew: ${parsedCrew.name} (${parsedCrew.faction}) with ${addedModels.length} models`;
      onLog(message);
      onClose();
    } catch (error) {
      console.error('Error during import:', error);
      onLog('Error importing crew. Please check the console for details.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Import Malifaux Crew</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Paste your crew list in the format below:
        </Typography>
        <Typography variant="caption" component="pre" style={{ backgroundColor: '#f5f5f5', padding: 8, borderRadius: 4 }}>
          CrewName (Faction)<br />
          Size: XX - Pool: Y<br />
          Leader:<br />
          &nbsp;&nbsp;Leader Name<br />
          Totem(s):<br />
          &nbsp;&nbsp;Totem Name<br />
          Hires:<br />
          &nbsp;&nbsp;Hire Name 1<br />
          &nbsp;&nbsp;Hire Name 2<br />
          References:<br />
          &nbsp;&nbsp;Reference Name 1<br />
          &nbsp;&nbsp;Reference Name 2
        </Typography>

        <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
          The importer will try to match model names to the Malifaux database. Common variations and abbreviations are supported.
        </Typography>

        <TextField
          label="Paste Crew List"
          multiline
          rows={10}
          variant="outlined"
          fullWidth
          margin="normal"
          value={crewText}
          onChange={handleTextChange}
          error={!!error}
          helperText={error}
        />

        {parsedCrew && (
          <>
            <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
              Parsed Crew: {parsedCrew.name} ({parsedCrew.faction})
            </Typography>
            <Typography variant="body2">
              Size: {parsedCrew.size} - Pool: {parsedCrew.pool}
            </Typography>

            <List dense>
              {parsedCrew.leader && (
                <>
                  <ListItem>
                    <ListItemText primary="Leader" secondary={parsedCrew.leader} />
                  </ListItem>
                  <Divider component="li" />
                </>
              )}

              {parsedCrew.totems.length > 0 && (
                <>
                  <ListItem>
                    <ListItemText
                      primary="Totems"
                      secondary={parsedCrew.totems.join(', ')}
                    />
                  </ListItem>
                  <Divider component="li" />
                </>
              )}

              {parsedCrew.hires.length > 0 && (
                <>
                  <ListItem>
                    <ListItemText
                      primary="Hires"
                      secondary={parsedCrew.hires.join(', ')}
                    />
                  </ListItem>
                  <Divider component="li" />
                </>
              )}

              {parsedCrew.references.length > 0 && (
                <ListItem>
                  <ListItemText
                    primary="References"
                    secondary={parsedCrew.references.join(', ')}
                  />
                </ListItem>
              )}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={importCrew}
          color="primary"
          variant="contained"
          disabled={!parsedCrew}
        >
          Import Crew
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportCrewDialog;
